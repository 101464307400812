.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 85%;
        left: 3%;
        width: 27%;
        padding: 25px;
        background: rgba(15,70,142,0.8);
        transform: translateY(-50%);

        &--title {
            font-size: $font-size-42;
            font-weight: bold;
            color: #fff;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }

    }
}