.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .ls-l a {
        width: auto;
    }
    .caption {
        position: absolute;
        top: $size-nav-height + 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: $z-index-slide-caption;
    }
    .title-slideshow {
        // background: rgba(216,0,0,0.8);
        background: rgba(15,70,142,0.8);
        width: 50%;
        padding: 50px 25px;
        h2 {
            font-weight: normal;
            font-size: $font-size-36;
            color: #fff;
            span {
                font-weight: bold;
                font-size: $font-size-42;
            }
        }
    }
}

