// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-burst {
    .blocBurst {
        display: flex;
        justify-content: space-between;
        padding: 1%;
        a.burst {
            background: #d80000;
            padding: 10px 20px;
            width: 32.5%;
            transition: 0.3s;
            p {
                font-weight: bold;
                font-size: $font-size-28;
                color: #fff;
                text-align: center;
                margin: 0;
                line-height: 1.3;
            }
            &:hover {
                background: #18468e;
                transition: 0.3s;
            }
        }
    }
}

#section-intro {
    background: #18468e; 
    padding: 50px 5% 25px 5%;
    .left-right {
        display: flex;
        justify-content: center;
        .left-side {
            margin-right: 7%;
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: #fff;
            margin: 0;
            line-height: 1.3;
            span {
                font-size: $font-size-28;
                font-weight: bold;
            }
        }
        p.last {
            padding-top: 5px;
        }
    }
    .texte {
        text-align: center;
        padding-top: 30px;
        h3 {
            font-weight: normal;
            font-size: $font-size-36;
            color: #fff;
            span {
                font-weight: bold;
                font-size: $font-size-40;
            }
        }
    }
}

#section-img-text {
    display: flex;
    justify-content: space-between;
    padding: 1%;
    .left {
        width: 35%;
    }
    .middle {
        width: 25%;
        margin: 0 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: #d80000;
            text-align: center;
            line-height: 1.3;
            padding-bottom: 10px;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-30;
            color: #d80000;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30px;
        h3 {
            font-weight: bold;
            font-size: $font-size-28;
            text-transform: initial;
            color: #18468e;
            padding-bottom: 30px;
        }
    }
}

#section-text-image {
    background: url("../images/accueil_section04_bg.jpg") no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 800px;
    border-bottom: 3px solid #fff;
    .left-side {
        h3 {
            font-weight: bold;
            font-size: $font-size-28;
            color: #fff;
            padding-bottom: 35px;
        }
        ul {
            list-style-image: url(../images/accueil_crochet.png);
            padding-left: 100px;
            li {
                font-weight: bold;
                font-size: $font-size-24;
                color: #fff;
                padding-bottom: 5px;
                padding-left: 40px;
            }
        }
    }
    .right-side {
        width: 50%;

        .carousel {
            &.carousel-slider {
                top: 0;
                left: 0;

                .carousel-fixed-item {
                    &.with-indicators {
                        bottom: 68px;
                    }

                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                    z-index: 1;
                }

                .carousel-item {
                    width: 100%;
                    height: 100%;
                    min-height: 400px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            overflow: hidden;
            position: relative;
            width: 100%;
            perspective: 800px;
            height: 35vw;
            transform-style: preserve-3d;
            //transform-origin: 0% 50%;

            .carousel-item {
                visibility: hidden;
                width: 100%;
                max-width: 42%;
                min-height: 426px;
                position: absolute;
                top: 0;
                left: 0;

                & > img {
                  width: 100%;
                }
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-intro_propos {
    background: #18468e; 
    padding: 75px 5%;
    .left-right {
        display: flex;
        justify-content: center;
        .left-side {
            margin-right: 7%;
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: #fff;
            margin: 0;
            line-height: 1.3;
            span {
                font-size: $font-size-28;
                font-weight: bold;
            }
        }
    }
    .texte {
        padding-top: 35px;
    }
}

#section-carousel {
    border-top: 5px solid #18468e;
    padding: 25px 0;
    position: relative;
    .carousel {
        &.carousel-slider {
            top: 0;
            left: 0;

            .carousel-fixed-item {
                &.with-indicators {
                    bottom: 68px;
                }

                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                z-index: 1;
            }

            .carousel-item {
                width: 100%;
                height: 100%;
                min-height: 400px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        overflow: hidden;
        position: relative;
        width: 100%;
        perspective: 800px;
        height: 35vw;
        transform-style: preserve-3d;
        //transform-origin: 0% 50%;

        .carousel-item {
            visibility: hidden;
            width: 100%;
            max-width: 22%;
            min-height: 426px;
            position: absolute;
            top: 0;
            left: 0;

            & > img {
                width: 100%;
            }
        }
    }
}

section.section-image-text {
    div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: center;
        & > div {
            min-height: 600px;
            transition: .3s;
            width: 50%;
        }
        @media screen and (max-width: $size-xs-max){
            flex-direction: column;
            transition: .3s;
            & > div {
                transition: .3s;
                width: 100%;
            }
        }
    }
    div.image-side {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
    }
    div.text-side {
          padding: 5%;
          display: flex;
          align-items: center;
          justify-content: center;
    }
    p {
        font-weight: normal;
        font-size: $font-size-24;
        color: #18468e;
        margin: 0;
        line-height: 1.3;
        @media screen and (max-width: $size-xs-max){
            text-align: center;
            transition: .3s;
        }    
    }
    .titre { 
        padding-top: 50px; 
        text-align: center;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: #18468e;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-36;
            color: #18468e;
            text-transform: uppercase;
            padding-bottom: 5px;
        }
    }
}

/*******************************************************************************
* PEINTRE SPÉCIALISÉ
*******************************************************************************/

#section-intro.peintre {
    padding: 75px 5%;
    .texte {
        padding-top: 0;
        padding-bottom: 50px;
    }
}

#section-txt-icon {
    padding: 75px 2%;
    display: flex;
    justify-content: space-around;
    border-bottom: 5px solid #18468e;
    .left-side, .right-side {
        display: flex;
        h3 {
            font-weight: bold;
            font-size: $font-size-28;
            color: #18468e;
            padding-bottom: 25px;
        }
        ul {
            list-style-image: url(../images/peintres_specialises_section03_fleche_bleue.jpg);
            padding-left: 65px;
            li {
                font-weight: bold;
                font-size: $font-size-28;
                color: #18468e;
                text-transform: initial;
                padding-left: 15px;
            }
        }
        .texte {
            width: 85%;
        }
    }
    .right-side {
        align-items: center;
    }
}

#section-parallax {
    background: url(../images/peintres_specialises_section04_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 600px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .left-side {
        h3 {    
            font-weight: normal;
            font-size: $font-size-36;
            color: #fff;
            padding-bottom: 25px;
            text-align: center;
            span {
                font-weight: bold;
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-36;
            color: #fff;
            margin: 0;
            line-height: 1.5;
            text-align: center;
        }
    }
    .right-side {
        width: 50%;
        padding-top: 2%;
        p {
            font-weight: 600;
            font-size: $font-size-32;
            color: #fff;
            text-align: center;
            margin: 0;
            line-height: 1.5;
        }
        .img-responsive {
            padding-top: 15px;
            margin: auto;
        }
    }
}


/*******************************************************************************
* TRAVAUX EN HAUTEUR
*******************************************************************************/

#section-txt-logo {
    padding: 5px 7%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 5px solid #18468e;
    .left-side {
        p {
            font-weight: bold;
            font-size: $font-size-30;
            color: #18468e;
            margin: 0;
            line-height: 1.5;
        }
    }
}

#section-liste {
    padding: 50px 10%;
    border-top: 5px solid #18468e;
    h3 {
        font-weight: bold;
        font-size: $font-size-28;
        color: #18468e;
        text-transform: initial;
    }
    .liste {
        display: flex;
        justify-content: space-between;
        padding-top: 35px;
        ul {
            list-style-image: url(../images/peintres_specialises_section03_fleche_bleue.jpg);
            padding-left: 65px;
            li {
                font-weight: bold;
                font-size: $font-size-28;
                color: #18468e;
                text-transform: initial;
                padding-left: 15px;
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#blocRealisations {
    background: #18468e;
    .section-projets {
        padding: 100px 5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .col-xs-12.col-sm-4 {
            transition: 0.3s;
            &:hover {
                transform: scale(1.05);
                z-index: 1;
                transition: 0.3s;
            }
        }
     }  
}

/***** INFO POP-UP *****/
.mfp-bg {
    background: #fff;
    opacity: 0.9;
}
.mfp-bottom-bar {
    h3 {
        font-weight: bold;
        font-size: $font-size-32;
        text-align: center;
        color: #18468e;
        padding: 5px 0;
    }
           
    p {
        font-weight: normal;
        font-size: $font-size-24;
        text-align: center;
        color: #18468e;
        margin: 0;
        line-height: 1.3;
    }   
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    background: #18468e;
    padding: 75px 5%;
    p {
        font-weight: normal;
        font-size: $font-size-24;
        color: #fff;
        margin: 0;
        line-height: 1.5;
        padding-left: 5%;
    }
    .icon-text {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 35px;
        .left-side {
            width: 12%;
        }
        .right-side {
            h3 {
                font-weight: normal;
                font-size: $font-size-32;
                color: #fff;
                padding-bottom: 5px;
                text-transform: initial;
            }
            h3.last {
                padding-top: 10px;
                font-size: $font-size-40;
                text-decoration: underline;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    .formulaire {
        padding-top: 30px;
        padding-left: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-28;
            color: #fff;
            text-transform: initial;
            padding-bottom: 50px;
        }
        #right {
            display: flex;
            align-items: center;
            padding: 20px 22.2% 0 22.2%;
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1795px) {
   
.slideshow-wrap .title-slideshow {
    width: 60%;
}
#section-intro .left-right p br,  #section-intro_propos .left-right p br {
    display: none;
}
#section-intro_propos.second .left-right .left-side {
    margin-right: 5%;
    width: 50%;
}
#section-intro_propos.second .left-right .right-side {
    width: 50%;
}
#section-coordonnees .formulaire {
    padding-left: 10%;
}
}

@media screen and (max-width: 1755px) {

.module-header--caption {
    top: 85%;
    left: 3%;
    width: 35%;
}
#section-txt-logo .left-side {
    width: 65%;
}
#section-txt-logo .left-side p br, #section-coordonnees p br {
    display: none;
}
#section-txt-logo {
    padding: 5px 3%;
}
#section-liste {
    padding: 50px 8%;
}
}

@media screen and (max-width: 1635px) {
   
#section-img-text .middle h4 br {
    display: none;
}
#section-img-text .middle {
    width: 28%;
}
.module-header--caption {
    top: 85%;
    left: 3%;
    width: 38%;
}
#section-parallax {
    padding: 0 5%;
}
#section-parallax .right-side {
    padding-top: 2%;
}
}

@media screen and (max-width: 1500px) {
   
.slideshow-wrap .title-slideshow {
    width: 65%;
}
#section-img-text .left {
    width: 44%;
}
#section-txt-icon .left-side, #section-txt-icon .right-side {
    width: 45%;
}
#section-txt-icon .left-side ul li, #section-txt-icon .right-side ul li {
    line-height: 1.5;
    padding-bottom: 10px;
}
#section-txt-logo {
    padding: 5px 5%;
}
}

@media screen and (max-width: 1370px) {
   
.slideshow-wrap .title-slideshow {
    width: 70%;
}
#section-img-text .middle h3 {
    font-size: 1.8rem;
}
#section-img-text .middle h4 {
    font-size: 1.3rem;
}
#section-parallax .right-side p {
    font-size: 1.4rem;
}
#section-parallax .left-side h3, #section-parallax .left-side p {
    font-size: 1.6rem;
}
#section-parallax .left-side {
    margin-right: 2%;
}
#section-parallax .right-side {
    padding-top: 4%;
    width: 45%;
}
}

@media screen and (max-width: $size-md-max) {
   
nav.module-menu .menu-navbar {
    height: 105px;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 400px;
}
.slideshow-wrap .title-slideshow {
    position: absolute;
    left: -8%;
    top: -50%;
}
section.section-image-text div.container-fluid.row > div {
    min-height: 300px;
}
.module-header--caption {
    top: 83%;
    left: 2%;
    width: 40%;
}
#section-parallax {
    padding: 0 2%;
}
#section-parallax .right-side {
    padding-top: 5%;
}
#section-coordonnees .icon-text .left-side {
    width: 15%;
}
}

@media screen and (max-width: 1024px) {

.slideshow-wrap .title-slideshow {
    left: -8%;
    top: -105%;
    width: 85%;
}
#section-intro .left-right {
    flex-direction: column;
}
#section-intro .left-right .left-side {
    margin-right: 0;
    padding-bottom: 25px;
}
#section-img-text {
    flex-direction: column;
}
#section-img-text .left {
    width: 45%;
    margin: auto;
    padding-bottom: 25px;
}
#section-img-text .middle {
    width: 65%;
    margin: auto;
    padding-bottom: 25px;
}
#section-img-text .right {
    width: 68%;
    margin: auto;
}
#section-text-image .right-side .carousel {
    height: 45vw;
}
#section-carousel {
    padding: 25px 0 0 0;
}
.module-header--caption {
    top: 83%;
    left: 2%;
    width: 45%;
}
#section-txt-icon {
    flex-direction: column;
}
#section-txt-icon .left-side, #section-txt-icon .right-side {
    width: 100%;
}
#section-txt-icon .left-side {
    padding-bottom: 25px;
}
#section-txt-icon .left-side .texte, #section-txt-icon .right-side .texte {
    width: 55%;
}
#section-burst .blocBurst a.burst {
    display: flex;
    justify-content: center;
    align-items: center;
}
#section-txt-logo .left-side {
    width: 100%;
}
#section-liste .liste ul li {
    font-size: 1.2rem;
}
#section-coordonnees .formulaire {
    padding-left: 5%;
}
#section-coordonnees p {
    padding-left: 0;
}
#section-coordonnees .icon-text .left-side {
    width: 20%;
}
#section-coordonnees .formulaire #right {
    padding: 20px 0 0 0;
    flex-direction: column;
    margin-left: -15.5%;
}
#section-coordonnees .formulaire form label.image {
    margin-top: 10px;
}
}

@media screen and (max-width: $size-sm-max) {

.slideshow-wrap .title-slideshow {
    left: -8%;
    top: -160%;
    width: 100%;
}
#section-text-image {
    background: #18468e;
    flex-direction: column;
    padding: 50px 2%;
    align-items: end;
}
#section-text-image .right-side {
    margin: auto;
}
#section-img-text {
    padding: 25px 0;
}
#section-text-image .right-side .carousel {
    height: 56vw;
}
#section-text-image .right-side .carousel .carousel-item {
    min-width: 100%;
}
#section-text-image .right-side {
    padding-top: 30px;
    width: 70%;
}
.module-header--caption {
    top: 75%;
    left: 2%;
    width: 55%;
}
section.section-image-text .titre h4 {
    font-size: 1.6rem;
}
section.section-image-text .titre h3 {
    font-size: 1.7rem;
}
#section-carousel .carousel .carousel-item {
    min-width: 50%;
}
#section-carousel .carousel {
    height: 85vw;
}
#section-parallax {
    flex-direction: column;
}
#section-parallax .left-side {
    margin-right: 0;
}
#section-parallax .right-side {
    padding-top: 0;
    width: 100%;
}
#section-txt-logo {
    padding: 20px 5%;
}
#section-liste .liste {
    padding-top: 30px;
    flex-direction: column;
}
#section-liste .liste ul li {
    font-size: 1.4rem;
}
#section-coordonnees .formulaire #right {
    margin-left: -4.5%;
}
}

@media screen and (max-width: $size-xs-max) {
 
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 275px;
    padding: 0 5px;
}
nav.module-menu .menu-navbar {
    height: 85px;
}
#section-burst .blocBurst {
    flex-direction: column;
}
#section-burst .blocBurst a.burst {
    padding: 15px 20px;
    width: 100%;
    margin-bottom: 5px;
}
#section-burst .blocBurst a.burst:last-child {
    margin-bottom: 0;
}
#section-img-text .left {
    width: 75%;
}
#section-img-text .middle {
    width: 100%;
}
#section-img-text .right {
    width: 100%;
    padding-top: 0;
}
#section-text-image .left-side ul {
    padding-left: 70px;
}
#section-text-image .right-side {
    width: 100%;
}
#section-text-image .right-side .carousel {
    height: 162vw;
}
.module-header--caption {
    top: 80%;
    left: 2%;
    width: 83%;
}
#section-intro_propos .left-right {
    flex-direction: column;
}
#section-intro_propos .left-right .left-side {
    margin-right: 0;
    padding-bottom: 25px;
}
#section-intro_propos {
    padding: 50px 5%;
}
#section-carousel .carousel {
    height: 100vw;
}
#section-carousel .carousel .carousel-item {
    min-width: 60%;
}
#section-intro_propos.second .left-right .left-side {
    margin-right: 0;
    width: 100%;
}
#section-intro_propos.second .left-right .right-side {
    width: 100%;
}
#section-intro.peintre, #section-coordonnees {
    padding: 50px 5%;
}
#section-txt-icon .left-side .icon, #section-txt-icon .right-side .icon {
    display: none;
}
#section-txt-icon .left-side .texte, #section-txt-icon .right-side .texte {
    width: 100%;
}
#section-txt-icon {
    padding: 50px 2%;
}
#section-txt-logo {
    padding: 25px 5%;
    flex-direction: column;
}
#section-liste {
    padding: 30px 5%;
}
#section-coordonnees .icon-text {
    padding-top: 20px;
    flex-direction: column;
}
#section-coordonnees .icon-text .left-side {
    width: 25%;
    padding-bottom: 15px;
}
#section-coordonnees .formulaire .form-group .flex {
    flex-direction: column;
}
#section-coordonnees .formulaire form label {
    width: 100%;
}
#section-coordonnees .formulaire {
    padding-left: 0;
}
#section-coordonnees .formulaire #right {
    margin-left: 0;
    padding: 0;
}
}

@media screen and (max-width: 360px) {

#section-carousel .carousel {
    height: 125vw;
}
#section-intro_propos.second {
    margin-top: -60px;
}
#section-coordonnees .icon-text .right-side h3 {
    font-size: 1.8rem;
}
#section-coordonnees .formulaire .btn-submit, #section-coordonnees .formulaire form label.image {
    min-width: 300px !important;
}
}